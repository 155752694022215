import * as cookieState from 'utils/cookieState';

import {
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_DATE,
  SET_INSURANCE_PAYER,
  SET_INSURANCE_MEMBER_ID,
  SET_INSURANCE_PAYER_ID,
  SET_INSURANCE_SUCCESS,
  SET_CLEAR_INSURANCE,
  SET_INSURANCE_ERROR,
  SET_LOADING,
  SET_VALID_INSURANCE_OBJECT,
  SET_CLEAR_INSURANCE_STORE,
  SET_COVERAGE_ID,
  SET_COVERAGE_TYPE,
  SET_IN_NETWORK,
  SET_EXPLANATION,
  CLEAR_PAYER,
} from 'src/js/actionTypes/InsuranceActionTypes';

export function setFirstName(firstName) {
  return {
    type: SET_FIRSTNAME,
    value: firstName,
  };
}

export function setLastName(lastName) {
  return {
    type: SET_LASTNAME,
    value: lastName,
  };
}

export function setClearInsurance() {
  return {
    type: SET_CLEAR_INSURANCE,
  };
}

export function setDate(date) {
  return {
    type: SET_DATE,
    value: date,
  };
}

export function setPayerId(payId) {
  return {
    type: SET_INSURANCE_PAYER_ID,
    value: payId,
  };
}

export function setPayerName(payer) {
  return {
    type: SET_INSURANCE_PAYER,
    value: payer,
  };
}

export function setMemberId(memberId) {
  return {
    type: SET_INSURANCE_MEMBER_ID,
    value: memberId,
  };
}

export function setCoverageType(coverageType) {
  return {
    type: SET_COVERAGE_TYPE,
    value: coverageType,
  };
}

export function setInsuranceSuccess(success) {
  return {
    type: SET_INSURANCE_SUCCESS,
    value: success,
  };
}
export function setInsuranceError(error) {
  return {
    type: SET_INSURANCE_ERROR,
    value: error,
  };
}

export function setCoverageID(coverageID) {
  return {
    type: SET_COVERAGE_ID,
    value: coverageID,
  };
}

export function setHasValidInsuranceObject(hasValidInsuranceObject) {
  return {
    type: SET_VALID_INSURANCE_OBJECT,
    value: hasValidInsuranceObject,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    value: loading,
  };
}

export function setClearInsuranceStore() {
  return {
    type: SET_CLEAR_INSURANCE_STORE,
  };
}

export function setPayer(id, name) {
  return (dispatch) => {
    dispatch(setPayerName(name));
    dispatch(setPayerId(id));
    cookieState.set('payer', { id, name });
  };
}

export function setInNetwork(inNetwork) {
  return {
    type: SET_IN_NETWORK,
    value: inNetwork,
  };
}

export function setExplanation(explanation) {
  return {
    type: SET_EXPLANATION,
    value: explanation,
  };
}

export const clearPayer = (dispatch) => {
  dispatch({
    type: CLEAR_PAYER,
  });
  cookieState.clear('payer');
};

export const setExistingInsurance = (existingData) => ((dispatch) => {
  dispatch(setFirstName(existingData.first_name));
  dispatch(setLastName(existingData.last_name));
  dispatch(setDate(existingData.date));
  dispatch(setPayer(existingData.payer_id, existingData.payer_name));
  dispatch(setCoverageType(existingData.coverage_type));
  dispatch(setMemberId(existingData.member_id));
  dispatch(setInsuranceError(null));
  dispatch(setHasValidInsuranceObject(true));
});

export const setSuccessfulInsurance = ({
  user,
  coverageID,
  coverageType,
  in_network,
  explanation,
  groupName = null,
}) => (
  (dispatch) => {
    dispatch(setFirstName(user.firstName));
    dispatch(setLastName(user.lastName));
    dispatch(setDate(user.date));
    dispatch(setPayer(user.payerId, groupName || user.payer || user.payerName));
    dispatch(setMemberId(user.memberId || user.member_id));
    dispatch(setCoverageID(coverageID));
    dispatch(setCoverageType(coverageType));
    dispatch(setInsuranceError(null));
    // saveSuccess is always true because this is only ever called when the lookup is successful
    // So i'm keeping the existing logic for now.. but why do we invert the success here?
    dispatch(setInsuranceSuccess(!true));
    dispatch(setHasValidInsuranceObject(true));
    dispatch(setInNetwork(in_network));
    dispatch(setExplanation(explanation));
    dispatch(setLoading(false));
  });

export const failInsuranceCheck = ({ error = null, formValues }) => ((dispatch) => {
  dispatch(setInsuranceSuccess(false));
  dispatch(setInsuranceError(error));

  dispatch(setLoading(false));

  // Persist failed insurance lookup for lookup forms
  dispatch(setPayer(formValues.payerId, formValues.payerName));
  dispatch(setMemberId(formValues.memberId));
});

export function setInitInsuranceModal() {
  return (dispatch) => {
    dispatch(setInsuranceError(null));
    dispatch(setLoading(false));
  };
}
